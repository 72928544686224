import Image from "next/image"
import classNames from 'classnames'
import Link from 'next/link'
import senseFlyLogo from '../../public/assets/images/sensefly.png'
import mvAgustaLogo from '../../public/assets/images/mv-agusta.svg'
import decathlonLogo from '../../public/assets/images/decathlon-logo.svg'
import apteraLogo from '../../public/assets/images/aptera_logo.svg'

export default function RotatingLogos({ currentTime }) {
  return (
    <div className="frame">
      <div className="queue">
        <div className="lines">
          <div
            className={classNames('line', {
              active: currentTime <= 13.5,
            })}
          >
            <Link passHref href="https://www.aptera.us/" target="_blank">

              <Image src={apteraLogo} alt="Aptera" priority={true} width="150" height="46" />

            </Link>
            <span className="d-block">The Most Efficient EV</span>
          </div>
          <div
            className={classNames('line', {
              active: currentTime > 13.5 && currentTime <= 28.5,
            })}
          >
            <Link passHref href="https://www.mvagusta.com/" target="_blank">

              <Image src={mvAgustaLogo} alt="MV Agusta" width="150" height="100" />

            </Link>
            <span className="d-block">Increasing front end downforce</span>
          </div>
          <div
            className={classNames('line', {
              active: currentTime > 28.5 && currentTime <= 42.5,
            })}
          >
            <Link passHref href="https://www.sensefly.com/" target="_blank">

              <Image src={senseFlyLogo} alt="SenseFly" width="150" height="46" />

            </Link>
            <span className="d-block">Extending drone range</span>
          </div>
          <div
            className={classNames('line', {
              active: currentTime > 42.5 && currentTime <= 60,
            })}
          >
            <Link passHref href="https://www.decathlon.com/" target="_blank">

              <Image src={decathlonLogo} alt="Decathlon" width="125" height="72" />

            </Link>
            <span className="d-block">Improving rider comfort</span>
          </div>
        </div>
      </div>
    </div>
  );
}
